import React from 'react'
import Layout from '~/components/layout'
import type { HeadProps } from 'gatsby'
import SEO from '~/components/seo'
import { Link } from 'gatsby'
import { TbZoomQuestion } from 'react-icons/tb'
import { BsArrowReturnLeft } from 'react-icons/bs'
import { css } from '@emotion/react'
import { COLOR_GRAY_DARK } from '~/styles/colors'
import { TAG_LINK_STYLE } from '~/styles/common'

interface Props {}

const NotFoundPage: React.FC<Props> = () => {
  return (
    <Layout>
      <div css={style.wrapper}>
        <h2>
          <TbZoomQuestion />
          404
        </h2>
        <h3>Page Not Found!</h3>
        <p>
          お探しのページが見つかりませんでした。
          <br />
          URLが正しく入力されているかご確認ください&#129488;
        </p>

        <div css={style.a}>
          <Link to="/">
            ホームに戻る <BsArrowReturnLeft />
          </Link>
        </div>
      </div>
    </Layout>
  )
}

const style = {
  wrapper: css`
    padding: 2em 0;
    color: ${COLOR_GRAY_DARK};

    h2 {
      font-size: 240%;
      svg {
        position: relative;
        top: 4px;
      }
    }

    h3 {
      font-size: 112%;
    }

    p {
      padding: 2em 0;
      line-height: 1.75;
    }
  `,
  a: css`
    margin: 2em 0;
    a {
      ${TAG_LINK_STYLE};
    }

    svg {
      position: relative;
      top: 2.5px;
    }
  `,
}

export default NotFoundPage

const SEO_TITLE = `404`
const SEO_DESC = `ストイック倶楽部ではまだまだ訪れることのできていない場所がたくさんあります！まだページが見つからなくても、いずれ見つかる日がやってきます！`

export const Head: React.FC<HeadProps> = () => {
  return (
    <SEO title={SEO_TITLE} description={SEO_DESC}>
      <meta name="robots" content="noindex,follow" />
    </SEO>
  )
}
